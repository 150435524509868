import BaseController from './BaseController'

export default class GrapplingBeamController extends BaseController {
  press() {
    console.warn('TODO')
  }
  release() {
    console.warn('TODO')
  }
  canCharge() {
    console.warn('TODO')
  }
}
